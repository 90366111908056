@use 'sass:math';

@import '../colors';
@import './fb-variables';
@import '../bootstrap/bootstrap/mixins/text-overflow';

.btn.btn-foodback--secondary {
  border: 1px solid $fb-main-color !important;
  background-color: transparent !important;
  color: $fb-main-color !important;
}

.btn.btn-foodback {
  background: $fb-main-color;
  color: white;
  text-transform: uppercase;
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    color: white;
  }
}

.btn-outline.btn-foodback {
  color: $fb-main-color !important;
  border-color: $fb-main-color !important;
  background: transparent;

  &:hover {
    color: $fb-main-color !important;
    text-decoration: underline !important;
  }
}

.btn-link.btn-foodback {
  color: $fb-main-color;
  border: none;
  background: transparent;
  text-transform: none;

  &:hover {
    color: $fb-main-color;
  }
}

.btn-foodback-gray {
  background-color: #717171 !important;
}

.btn-transparent {
  border: none;
  background: transparent;
}

.content-heading {
  font-family: 'Komet', sans-serif;
  padding: 16px;
}

.content-sub-heading {
  height: 44px;
  min-height: 44px;
  border-bottom: 1px solid $panel-global-border;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FilterPanel {
  border-bottom: 1px solid $panel-global-border;
  font-size: $font-size-h4;
  background-color: $fb-container-color;

  &-filters {
    flex: 1 0 auto;
  }

  &-label {
    font-size: 14px;
  }
}

.empty-list-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .heading-text {
    flex: 1 0;
    margin: 0;
    line-height: math.div($logo-component-height, 2);
  }
}

.empty-list {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    width: 225px;
    text-align: center;
    max-width: 100%;

    &.wide {
      width: 590px;
    }
  }
  .btn-foodback {
    text-transform: uppercase;
  }
}

.clickable-icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.user-tips {
  margin: 10px 0 20px 0;
}

.tooltip {
  font-size: 12.5px;

  &.bottom {
    .tooltip-arrow {
      border-bottom-color: $tooltip-bg;
    }
  }

  &.top {
    .tooltip-arrow {
      border-top-color: $tooltip-bg;
    }
  }

  &.left {
    .tooltip-arrow {
      border-left-color: $tooltip-bg;
    }
  }

  &.right {
    .tooltip-arrow {
      border-right-color: $tooltip-bg;
    }
  }

  .tooltip-inner {
    color: #000;
    background: $tooltip-bg;
    max-width: none;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
    .paragraphWidthLimiter {
      width: $tooltip-paragraph-width;
    }
  }
}

.icon-btn {
  cursor: pointer;
}

.icon-btn-disabled {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.2);
}

.fb-no-select {
  user-select: none;
}

.fb-text-success {
  color: $success;
}

.fb-text-warning {
  color: $warning;
}

.fb-text-danger {
  color: $danger;
}

.fb-text-info {
  color: #8d8d8d;
}

.fb-text-color-foodback {
  color: $fb-main-color;
}

.fb-semiTransparent {
  opacity: 0.5;
}

.fb-required-field {
  &:after {
    content: '*';
    color: $fb-main-color;
    margin-left: 0.4rem;
  }
}

.fb-link {
  color: $fb-main-color !important;

  &:hover {
    color: $fb-main-color !important;
  }
}

.switch {
  input[type='radio']:checked,
  input[type='checkbox']:checked {
    &:disabled + span {
      opacity: 0.5;
      border-color: $purple-light;
      background-color: $purple-light;

      &:after {
        background-color: #eee;
      }
    }

    & + span {
      border-color: $purple-light;
      background-color: $purple-light;
    }
  }
}

.ui-select-choices-row.active > .dropdown-item {
  background: $fb-main-color !important;
}

.page-header {
  margin-bottom: 10px !important;
}

.pagination {
  > .active > a {
    background-color: $fb-main-color !important;
    border-color: $fb-main-color !important;

    &:hover {
      background-color: #fff !important;
    }
  }

  > li > a:hover {
    color: $fb-main-color !important;
  }
}

.vertical-positioner {
  margin: auto;
}

.fb-dashboard {
  max-width: 1500px;
  margin: auto;
}

.panel {
  flex-grow: 1;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;

  .panel-body {
    flex-grow: 1;

    &.panel-body-condensed {
      padding: 0 15px 5px;
    }
  }
}

.row-equal {
  display: flex;
  flex-flow: row wrap;

  &::before {
    display: block;
  }

  > [class*='col-'],
  .col {
    display: flex;
    flex-direction: column;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.preloader-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 60px;
}

.fb-select-overflow {
  .ui-select-match-text {
    @include text-overflow();
    max-width: 100%;
  }
}

.swiper {
  .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-first {
    margin: 0 !important;
  }

  .swiper-pagination-bullet-active {
    border: none !important;
    background-color: $fb-main-color;
  }

  .DashboardPanel--featuredFoodbacks & {
    //specific styling related to swiper dots inside featured foodbacks
    .swiper-container {
      position: static;
    }

    .swiper-pagination-bullets {
      bottom: 0 !important;
      height: 37px;
      line-height: 37px;
    }

    .swiper-pagination-handle {
      display: inline !important;
    }
  }
}

// override slider classes
.noUi-horizontal {
  height: 6px;
  border: none;

  html:not([dir='rlt']) & .noUi-handle {
    right: -8px;
  }

  .noUi-connects {
    background-color: #cccccc;
  }

  .noUi-handle {
    box-shadow: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: -5px;
    border: none;
    background-color: #3fb8af;

    &:before,
    &:after {
      content: none;
    }
  }
}

.wrapper > section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0 !important;
}

.swal2-modal {
  font-family: inherit !important;
}

.swal2-confirm {
  background-color: $fb-main-color !important;
}

.container-lg {
  max-width: 1500px !important;
  width: 100%;
  margin: 0 auto;
  padding: 25px 16px;
  display: block;
}

.no-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

table {
  width: 100%;
  table-layout: auto !important;
  //min-width: 1000px;

  th,
  td {
    white-space: nowrap;
  }

  &.table-fixed {
    table-layout: fixed !important;
  }
}

@media only print {
  .wrapper > section {
    position: initial;
  }
}
