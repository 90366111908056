@font-face {
  font-family: 'FoodbackFont';
  src: url('./font/foodback.eot');
  src: url('./font/foodback.eot') format('embedded-opentype'), url('./font/foodback.woff') format('woff'),
    url('./font/foodback.ttf') format('truetype'), url('./font/foodback.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fb-font {
  display: inline-block;
  font: normal normal normal 14px/1 FoodbackFont;
  color: white;
}

.fb-font-18px:before {
  font-size: 18px;
}

.fb-font-smiley:before {
  content: '\e900';
}

.fb-font-account-white:before {
  content: '\e901';
}

.fb-font-account:before {
  content: '\e902';
}

.fb-font-venue-white:before {
  content: '\e904';
}

.fb-font-venue:before {
  content: '\e905';
}
