.fb-category-icon {
  @function getIconPath($categoryName) {
    @return '/assets/svg/#{$categoryName}.svg';
  }
  $icon-size: 32px;
  background-size: $icon-size;
  height: $icon-size;
  width: $icon-size;
  display: inline-block;
  border-radius: 50%;

  @mixin setIconWithColor($iconName) {
    background-color: white; /* Kolor SVG */
    -webkit-mask: url(getIconPath($iconName)) no-repeat center;
    -webkit-mask-size: contain;
    mask: url(getIconPath($iconName)) no-repeat center;
    
    mask-size: 80%;
    // background: url(getIconPath($iconName));
  }

  &--statement_category\.atmosphere,
  &--statement_category\.takeaway\.atmosphere {
    @include setIconWithColor('statement_category.atmosphere');
  }

  &--statement_category\.meal,
  &--statement_category\.takeaway\.meal {
    @include setIconWithColor('statement_category.meal');
  }

  &--statement_category\.restaurant,
  &--statement_category\.takeaway\.restaurant {
    @include setIconWithColor('statement_category.restaurant');
  }

  &--statement_category\.service,
  &--statement_category\.takeaway\.service {
    @include setIconWithColor('statement_category.service');
  }

  &--statement_category\.order,
  &--statement_category\.takeaway\.order {
    @include setIconWithColor('statement_category.order');
  }

  &--statement_category\.cleanliness,
  &--statement_category\.takeaway\.cleanliness {
    @include setIconWithColor('statement_category.cleanliness');
  }

  &--statement_category\.pickup,
  &--statement_category\.takeaway\.pickup {
    @include setIconWithColor('statement_category.pickup');
  }

  &--statement_category\.canteen,
  &--statement_category\.takeaway\.canteen {
    @include setIconWithColor('statement_category.canteen');
  }

  &--statement_category\.flexible_category_1,
  &--statement_category\.takeaway\.flexible_category_1 {
    @include setIconWithColor('statement_category.flexible_category_1');
    background-size: cover;
  }

  &--statement_category\.flexible_category_2,
  &--statement_category\.takeaway\.flexible_category_2 {
    @include setIconWithColor('statement_category.flexible_category_2');
    background-size: cover;
  }

  &--statement_category\.flexible_category_3,
  &--statement_category\.takeaway\.flexible_category_3 {
    @include setIconWithColor('statement_category.flexible_category_3');
    background-size: cover;
  }

  &--statement_category\.flexible_category_4,
  &--statement_category\.takeaway\.flexible_category_4 {
    @include setIconWithColor('statement_category.flexible_category_4');
    background-size: cover;
  }

  &--statement_category\.overall_experience,
  &--statement_category\.takeaway\.overall_experience {
    background: $fb-dashboard-widget url('/assets/svg/icon-foodback-smiley-white.svg') no-repeat 50% 50%;
    background-size: 55%;
  }

  &--statement_category\.takeaway\.packing {
    background: $fb-dashboard-widget url('/assets/svg/statement_category.packing.svg') no-repeat 50% 50%;
    background-size: 55%;
  }

  &--statement_category\.takeaway\.other {
    background: $fb-dashboard-widget url('/assets/svg/statement_category.other.svg') no-repeat 50% 50%;
    background-size: 55%;
  }
}
