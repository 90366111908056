@import '../app/media-queries';
$default-user-table-height: 50px;

table.fb-table-management-list > thead > tr > th,
table.fb-table-management-list > thead > tr > td,
table.fb-table-management-list > tbody > tr > th,
table.fb-table-management-list > tbody > tr > td,
table.fb-table-management-list > tfoot > tr > th,
table.fb-table-management-list > tfoot > tr > td {
  line-height: normal;
  height: 45px;

  @media (min-width: $mq-tablet) {
    height: 55px;
  }

  @media (min-width: $mq-desktop) {
    height: 45px;
  }
}
