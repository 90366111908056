/* ========================================================================
     Component: circles
 ========================================================================== */

//
// Colored circle for indication purposes
//

$point-sz-sm: 5px;
$point-sz: 7px;
$point-sz-lg: 11px;
$point-sz-xl: 18px;

.circle {
  display: inline-block;
  width: $point-sz;
  height: $point-sz;
  border-radius: 500px;
  margin: 0 0.5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent;
  &.text-left {
    margin-left: 0;
  }
  &.text-right {
    margin-right: 0;
  }
}

.circle-primary {
  background-color: $brand-primary;
}
.circle-success {
  background-color: $brand-success;
}
.circle-info {
  background-color: $brand-info;
}
.circle-warning {
  background-color: $brand-warning;
}
.circle-danger {
  background-color: $brand-danger;
}
.circle-green {
  background-color: $brand-green;
}
.circle-pink {
  background-color: $brand-pink;
}
.circle-inverse {
  background-color: $brand-inverse;
}
.circle-purple {
  background-color: $brand-purple;
}

.circle-sm {
  width: $point-sz-sm;
  height: $point-sz-sm;
}

.circle-lg {
  width: $point-sz-lg;
  height: $point-sz-lg;
}

.circle-xl {
  width: $point-sz-xl;
  height: $point-sz-xl;
}

.circle-outline {
  background-color: transparent;
  &.circle-primary {
    border: 1px solid $brand-primary;
  }
  &.circle-success {
    border: 1px solid $brand-success;
  }
  &.circle-info {
    border: 1px solid $brand-info;
  }
  &.circle-warning {
    border: 1px solid $brand-warning;
  }
  &.circle-danger {
    border: 1px solid $brand-danger;
  }
}
