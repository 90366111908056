// GENERAL
$fb-default-margin: 16px;

// STATISTICS
// @todo remove when demographics get date range picker
$fb-header-title-width: calc(100% / 2 - 150px);
$fb-header-dateRange-width: calc(100% / 2 + 150px);

// AVATARS
$fb-sidebar-avatar-size: 46px;
$fb-collapsed-sidebar-avatar-size: 40px;
$fb-icon-inside-avatar: 10px;
$fb-collapsed-sidebar-icon-inside-avatar: 8px;

$logo-component-height: 66px;
$tooltip-paragraph-width: 200px;
$bill-folder-header-height: 66px;

$all-filters-in-one-line-width: 1300px;
