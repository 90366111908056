@import 'colors';
@import '../../../../shared/styles/app/variables';

.mat-mdc-text-field-wrapper {
  padding: 0 0.45em 0 0.45em !important;
  height: 50px !important;
}

.mdc-floating-label {
  font-weight: unset !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 20px !important;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 20px !important;
}

.mdc-line-ripple::before, .mdc-line-ripple::after {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-datepicker-toggle {
  margin-bottom: 10px;

  button {
    height: unset !important;
    width: unset !important;
    padding: 0 !important;
  }
}

.mat-mdc-form-field-hint-spacer {
  display: none;
}

.mat-mdc-form-field-hint-wrapper {
  font-size: 75%;
  padding: 0 !important;
}

.mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: 75%;
}

.mat-mdc-form-field-subscript-wrapper {
  //height: 16px;
}

.mat-mdc-form-field {
  margin-bottom: 16px !important;
  font-size: 14px;
}

.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  height: 24px;
  width: 24px;
  padding: 0 !important;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 24px !important;
}

.TextareaWithCounter {
  .mat-mdc-text-field-wrapper {
    height: 100% !important;
  }
}

.TextareaInput {
  .mat-mdc-text-field-wrapper {
    height: auto !important;
  }
}

.mat-mdc-checkbox label {
  margin: 0;
  font-weight: 400;
}

.mat-mdc-checkbox .mdc-checkbox {
  padding-right: 5px !important;
}

.mat-mdc-input-element {
  width: calc(100% - 10px) !important;
}

//Datepicker
.mat-calendar-body-selected {
  color: $fb-material-selected-element-text-color !important;
}

.AllFoodbacksDatepicker {
  .mat-mdc-form-field {
    width: 150px !important;
  }
}

.mat-calendar-cell-semi-selected {
  background-color: $fbi-front-accent-brighter;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  background-color: $fbi-front-accent-brighter;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  background-color: $fbi-front-accent-brighter;
}

@media (max-width: $screen-xs) {
  .mat-datepicker-popup {
    top: 0 !important;
    left: 0 !important;
    width: 100%;
  }

  .mat-datepicker-content {
    width: 100%;

    .mat-calendar {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.mat-calendar-body-selected {
  background-color: #DC3855 !important;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}

.mat-calendar-body-in-range::before {
  background: rgba(232, 94, 108, 0.2) !important;
}

.mat-calendar-body-cell-content:hover {
  background: rgba(232, 94, 108, 0.3) !important;
}

.mat-datepicker-toggle {
  display: flex;
}

:not(.mat-mdc-form-field-appearance-legacy).mat-form-field .mat-form-field-prefix .mat-mdc-icon-button,
:not(.mat-form-field-appearance-legacy).mat-form-field .mat-form-field-suffix .mat-icon-button {
  height: unset !important;
}

.InputWithCounter,
.TextareaWithCounter {
  .mat-mdc-form-field-hint {
    flex: 1;
    text-align: end;
    display: flex;
    justify-content: space-between;
  }

  .mat-form-field-hint-spacer {
    display: none;
  }
}

//Snackbar
.mat-mdc-snack-bar-container {
  max-width: unset !important;
  min-width: unset !important;
  background-color: $fb-dark-gray !important;
  opacity: 0.95 !important;
}

.mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
  max-width: 100% !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: unset !important;
}

@mixin borderStatusColor($color) {
  border-left: 5px solid $color;
}

.SnackBar {
  &-error {
    @include borderStatusColor($danger);
  }

  &-success {
    @include borderStatusColor($success);
  }

  &-info {
    @include borderStatusColor($info);
  }

  &-warning {
    @include borderStatusColor($warning);
  }
}

.DateRangePicker {
  @mixin setDateRangePickerListHeight($maxHeight, $marginTop) {
    @media (max-device-width: $screen-md) and (orientation: landscape) {
      max-height: $maxHeight !important;
      margin-top: $marginTop !important;
    }
  }

  &-listOfItems {
    @include setDateRangePickerListHeight(50vh, 0);
  }

  &-listOfItemsDynamicMode {
    @include setDateRangePickerListHeight(50vh, 0);
  }
}

.Filter {
  &-dividerIcon {
    @media (max-width: $screen-xs) {
      display: none !important;
    }
  }
}

.cdk-overlay-dark-backdrop-transparent {
  background: transparent;
}

.mat-toolbar {
  border: none !important;
}

.mat-mdc-form-field-error {
  text-align: left;
}

.MultiSelectTag {
  &-single-tag {
    //max-width: 150px;
    display: inline-block;
    overflow: hidden;
    background-color: #d1d3f8;
    border-radius: 20px;
    align-items: center;
    padding: 4px 10px;
    font-size: 0.8rem;
    height: 26px;
    margin: 6px 6px 0 0;

    i {
      margin-left: 5px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.cdk-drag-preview {
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 1 !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag {
  opacity: 1 !important;
}

.collapsible {
  overflow: hidden;
}

.ThinCheckbox > label {
  font-weight: 400;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: unset !important;
  color: unset !important;
  letter-spacing: unset !important;
  line-height: unset !important;
}

.mat-mdc-option {
  font-size: 14px;
  padding-right: 20px !important;
}

.mdc-list-item__primary-text {
  width: 100%;
}
