// Created from https://github.com/lipis/flag-icon-css
// downloaded and not added through npm because of relative paths inside node_modules and https://github.com/angular/angular-cli/issues/8036
// added flag-icon-round and flag-icon-bigger classes. Unused flags removed to save space, add them on demand (svg file and CSS rule here)

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1em;
  line-height: 1em;
  margin-right: 5px;
}

.flag-icon:before {
  content: '\00a0';
}

.flag-icon-round {
  border-radius: 50%;
}

.flag-icon-bigger {
  width: 16px;
  height: 16px;
}

.flag-icon-en-GB {
  background-image: url('../../../assets/svg/flags/en-GB.svg');
}
.flag-icon-en-US {
  background-image: url('../../../assets/svg/flags/en-US.svg');
}

.flag-icon-nb-NO {
  background-image: url('../../../assets/svg/flags/nb-NO.svg');
}

.flag-icon-sv-SE {
  background-image: url('../../../assets/svg/flags/sv-SE.svg');
}

.flag-icon-de-DE {
  background-image: url('../../../assets/svg/flags/de-DE.svg');
}

.flag-icon-zh-Hans {
  background-image: url('../../../assets/svg/flags/zh-Hans.svg');
}

.flag-icon-pt-BR {
  background-image: url('../../../assets/svg/flags/pt-BR.svg');
}

.flag-icon-da-DK {
  background-image: url('../../../assets/svg/flags/da-DK.svg');
}

.flag-icon-es-ES {
  background-image: url('../../../assets/svg/flags/es-ES.svg');
}

.flag-icon-fi-FI {
  background-image: url('../../../assets/svg/flags/fi-FI.svg');
}

.flag-icon-fr-FR {
  background-image: url('../../../assets/svg/flags/fr-FR.svg');
}

.flag-icon-fr-CA {
  background-image: url('../../../assets/svg/flags/en-CA.svg');
}

.flag-icon-en-AU {
  background-image: url('../../../assets/svg/flags/en-AU.svg');
}

.flag-icon-en-CA {
  background-image: url('../../../assets/svg/flags/en-CA.svg');
}

.flag-icon-it-IT {
  background-image: url('../../../assets/svg/flags/it-IT.svg');
}

.flag-icon-nl-NL {
  background-image: url('../../../assets/svg/flags/nl-NL.svg');
}

.flag-icon-pt-PT {
  background-image: url('../../../assets/svg/flags/pt-PT.svg');
}

.flag-icon-pl-PL {
  background-image: url('../../../assets/svg/flags/pl-PL.svg');
}