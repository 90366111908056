.d-inline-block {
  display: inline-block;
}

.inner-content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.ml20 {
  margin-left: 20px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb10 {
  margin-bottom: 10px !important
}

.mb10 {
  margin-bottom: 5px !important
}

.pl20 {
  padding-left: 20px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.heading-text--mt {
  margin-top: 35px;
}

@media screen and (min-width: $screen-md) {
  .col-md-6-auto {
    width: auto !important;
    max-width: 50% !important;
  }

  .margin-top-md-hide {
    margin-top: 0 !important;
  }
}

// margin mixins
@mixin hideMarginTop($resolution-width) {
  @media (min-width: $resolution-width) {
    margin-top: 0 !important;
  }
}

@mixin hideMarginBottom($resolution-width) {
  @media (min-width: $resolution-width) {
    margin-bottom: 0 !important;
  }
}

@mixin addMarginLeft($resolution-width, $marginValue) {
  @media (min-width: $resolution-width) {
    margin-left: $marginValue !important;
  }
}

// padding mixins
@mixin hidePaddingTop($resolution-width) {
  @media (min-width: $resolution-width) {
    padding-top: 0 !important;
  }
}

@mixin hidePaddingLeft($from-width: 0px, $to-width: 10000px) {
  @media (min-width: $from-width) and (max-width: $to-width) {
    padding-left: 0 !important;
  }
}

@mixin hidePaddingRight($from-width: 0px, $to-width: 10000px) {
  @media (min-width: $from-width) and (max-width: $to-width) {
    padding-right: 0 !important;
  }
}

@mixin noGutters($from-width: 0px, $to-width: 10000px) {
  @media (min-width: $from-width) and (max-width: $to-width) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
