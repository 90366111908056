// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

//== Bootstrap
@import './app/shared/styles/bootstrap.scss';
@import url("https://use.typekit.net/xdi1aau.css");

//== Vendor
@import '../node_modules/font-awesome/css/font-awesome.css';
@import '../node_modules/simple-line-icons/css/simple-line-icons.css';

@import '../node_modules/loaders.css/loaders.css';

@import '../node_modules/sweetalert2/dist/sweetalert2.css';
@import '../node_modules/mdi/css/materialdesignicons.css';
@import '~nouislider/dist/nouislider.min.css';

//== Application
@import './app/shared/styles/app.scss';

@import './app/shared/styles/fb/font.css';
@import './app/shared/styles/app/variables.scss';
@import './app/shared/styles/colors.scss';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import './app/shared/styles/flags.scss';

@import './app/shared/styles/fb/general';
@import './app/shared/styles/fb/utilities';
@import './app/shared/styles/fb/tables';
@import './app/shared/styles/fb/fb-user-management-tables';
@import './app/shared/styles/fb/forms';
@import './app/shared/styles/fb/category-icons';
@import './app/shared/styles/fb/chart';
@import 'app/shared/styles/fb/material/theming';
@import 'app/shared/styles/fb/material/styles';

//@import '~swiper/swiper-bundle';
@import '~quill/dist/quill.snow.css';

.ql-disabled > .ql-editor, .ql-disabled > .ql-editor > * {
  opacity: .5;
  cursor: not-allowed;
}

.ql-toolbar {
  background: $pre-bg;
  border-color: $pre-bg !important;
  border-radius: 4px 4px 0 0;
}

.ql-bottom-bar {
  color: rgba(0, 0, 0, 0.6);
  font-size: 10.5px;
  display: flex;
  justify-content: space-between;
}

.ql-bottom-bar > p {
  text-align: end;
}

.richTextDisabled > .ql-toolbar {
  pointer-events: none;
  opacity: .5;
}

.ql-container {
  border-color: $pre-bg !important;
}

.ql-toolbar button:hover .ql-stroke, .ql-toolbar button.ql-active .ql-stroke {
  stroke: $fb-main-color !important;
}

.ql-toolbar button:hover .ql-fill, .ql-toolbar button.ql-active .ql-fill {
  fill: $fb-main-color !important;
}

.ql-snow a {
  color:  $fb-main-color;
}

$theme-primary: mat.define-palette($mat-primary);
$theme-accent: mat.define-palette($mat-accent);

$theme-warn: mat.define-palette($mat-warn);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
));

@include mat.all-component-themes($theme);

html,
body {
  overflow: hidden !important;
}

body {
  -webkit-overflow-scrolling: touch;
  margin: 0;
}

body,
html {
  font-family: 'Komet', serif !important;
  font-size: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.main-link {
  cursor: pointer;

  &:hover {
    color: $fb-main-color;
  }
}
.container-lg {
  min-height: 100%;
}

.form-control:focus {
  border-color: #989898 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.3) !important;
}

.container-lg {
  min-height: 100%;
}

.form-control:focus {
  border-color: #989898 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.3) !important;
}

.cdk-overlay-container {
  z-index: $z-index-main-section + 50;
}

.fc__tooltip.fusioncharts-div div .plottooltext-label {
  display: none;
}

.fc__tooltip.fusioncharts-div div:first-child .plottooltext-label {
  display: block;
  color: #fff !important;
}

.fakeLink {
  color: $fb-main-color;

  &:hover {
    cursor: pointer;
  }
}
.draggableIcon {
  width: 24px;
  color: $gray-light;

  &:hover {
    cursor: all-scroll;
  }
}

.error {
  color: red;
}

@media only print {
  ::-webkit-scrollbar {
    display: none;
  }
  html,
  body {
    overflow: visible !important;
  }
  @page {
    size: landscape;
  }
}

.intercom-lightweight-app-launcher {
  bottom: 24px !important;
}

.emptyBlock {
  content: '';
  width: 18px;
}
