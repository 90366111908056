/* ========================================================================
     Component: print
 ========================================================================== */

//
// Put here all rules required to style the print view
//
.print-visible {
  visibility: hidden;
  position: absolute;
}
@media print {
  .print-hide {
    visibility: hidden !important;
    position: absolute;
  }

  .print-visible {
    visibility: visible !important;
    position: relative;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
    min-height: 1px;;
  }

  .content-wrapper {
    padding: 10px !important;
  }

  .Dashboard-panelWrapper {
    width: 50% !important;

    &--2x {
      width: 100% !important;
    }
  }

  .VenueOverview {
    .row {
      div[class^='col-'] {
        width: 50% !important;
      }
    }
  }

  app-scoreboard-filters {
    display: none;
  }

  .btn-foodback {
    display: none;
  }
  div#paginator {
    display: none !important;
  }

  .TopLevelDashboardTable-printMode {
    tbody tr:nth-of-type(1) {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .FilterPanel-headerContainer {
    font-size: 20px;
  }

  app-foodback-overview {

    .DateRangePicker-header {
      display: none;
    }

    .PerformanceChartContainer {
      width: 50% !important;

      .panel {
        box-shadow: none;
      }

      .panel-body {
        max-width: 500px !important;
        padding-right: 0 100px 0 0 !important;
      }
    }
    .FilterPanel {
      display: none;
    }

    .DataCardWidgetStatisticsEntry {
      min-width: 140px !important;
      flex: 0 1 !important;
    }

    .FoodbackOverview__category {
      min-width: unset !important;
      width: 25% !important;
      margin-top: 5px;

      .panel {
        border-top: 1px solid #c8c8c8;
      }
    }
    .DataCardWidgetStatisticsEntry-number {
      margin-top: 10px;
      font-size: 20px !important;
    }

    .DataCardWidget-statistics {
      height: 100px !important;
    }

    .foodbacksOverviewChart {
      width: 100% !important;
      display: block !important;
      margin: 0 auto !important;

      .panel-body {
        margin: 0 auto !important;
      }
    }

    .DataCardWidget-circle {
      max-width: 100px;
      position: absolute !important;
    top: 40px !important;
    }

    .DataCardWidgetPanel {
      display: inline-flex !important;
      position: relative;
    }


    .VenueOverview-print {
      width: 100% !important;
    }

    .CategoryOverviewCard-circle {
      max-width: 100px !important;
      margin: 5px auto !important;
      font-size: 30px !important;
    }

    .PercentCircleWrapper-customContent {
      line-height: 20px !important;
      .h1 {
        font-size: 20px !important;
      }

      .h4 {
        font-size: 16px !important;
      }
    }

    .DataCardWidgetPanel {
      display: inline-block !important;
      width: 100% !important;
      position: relative !important;

      app-percent-circle {
        margin: 5px 0 10px 140px !important;
      max-width: 130px !important;
      max-height: 100px !important;
      position: fixed;
      }

      .DataCardWidgetPanel {
        height: 150px;
        margin-left: 140px;
      }
      .PercentCircleWrapper {
      width: 70% !important;
      line-height: 20px !important;

      .h1 {
        font-size:30px !important;
      }

      .h4 {
        font-size: 12px !important;
      }
      }
      
    }
  }

  .ActivityGraph-bar {
    background: red;
  }

  .Scoreboard .ScoreboardRating {
    display: inline !important;
  }

  .Scoreboard thead th {
    white-space: pre-wrap;
    font-size: 12px;
    padding: 2px;
  }

  .Scoreboard tr {
    line-height: 23px;
  }
}
