canvas.chart-clickable {
  cursor: pointer !important;
}

.chart-panel--noData {
  font-size: 28px;
  text-align: center;
  color: #cdcdcd;
  padding: 40px;
}
