// BRAND COLORS FROM DESIGN GUIDELINES
$fb-main-color: #DC3855;
$fb-accent1: #9da1ea;
$fb-accent2: #f6cd97;
$fb-accent3: #9cc652;
$fb-dark-gray: #1B1D3E;
$fb-light-gray: #323453;
$fb-gray-no-active: #b3b3b3;
$fb-default-font-color: #1B1D3E;
$fb-container-color: #fff;
$fb-light-gray-no-active: #f7f7fe;

$fb-dark-text: #1B1D3E;
$fb-light-text: #ffffff;

$fb-active-border: #cc404d;
$fb-active-label: #c5214e;

$fb-widget-light-blue: #8cb1d7;
$fb-widget-light-green: #7cd175;

$fb-radio-yellow: #f28500;
$fb-radio-yellow-sun: #faac18;
$fb-radio-green: #689f38;
$fb-radio-blue: #0288d1;
$fb-radio-light-blue: #0097a7;
$fb-radio-violet: #9575cd;
$fb-radio-pink: #f06292;

$fb-info: #d8e5f1;

$fb-progress-bar-yellow: #e5db00;

$fb-enforcement-beige: #feffeb;

// OVERVIEW WIDGET COLORS
$fb-dashboard-widget: #717171;
$fb-dashboard-category-averages-bar: #f2f2f2;
$fb-dashboard-category-averages-highlight: #fafafa;
$fb-dashboard-category-averages-no-data: #8d8d8d;
$fb-dashboard-category-averages-subheader: #cdcdcd;
$fb-dashboard-non-distracting-text: #aaa;

// DEMOGRAPHICS WIDGET COLORS
$fb-demographic-widget-emails: #f6cd97;
$fb-demographic-widget-date: #717171;
$fb-demographic-widget-demographics: #9cc652;

// DEMOGRAPHICS CHARTS
$fb-demographic-avg-age-bg: #f2f2f2;
$fb-demographic-avg-age-overall-border: #1B1D3E;
$fb-demographic-avg-age-male-border: #9da1ea;
$fb-demographic-avg-age-female-border: #ea8899;
$fb-demographic-avg-age-other-border: #dee0f8;

// SCOREBOARD
$fb-scoreboard-no-foodbacks: #cdcdcd;

//TOOLTIPS COLORS
$tooltip-bg: #000;
$tooltip-text-color: #fff;

// ANGLE BOOTSTRAP COLORS
$primary: #5d9cec;
$success: #8abf97;
$info: #23b7e5;
$warning: #ff902b;
$danger: #f05050;
$inverse: #131e26;
$green: #37bc9b;
$pink: #f532e5;
$purple: #7266ba;
$dark: #3a3f51;
$yellow: #fad732;
$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;
$content-heading-bg: #f7f7fe;
$white: #fff;
$purple-light: #d1d3f8;

// BASIC COLORS
$fb-bg-color: #f5f7fa;
// COMMON COLORS
$default-disable-color: #edf1f2;
$fb-missing-icon-color: #656565;

// RATING COLORS
$fb-rating-good: #009624;
$fb-rating-okay: #ff8800;
$fb-rating-bad: #d50000;
$fb-rating-neutral: #717171;

// DASHBOARD COLORS
$fb-dashboard-overall-experience-header: $fb-dark-gray;
$fb-dashboard-percent-circle-bg: #dddff9;
$fb-dashboard-percent-circle-fill-default: #717171;
$fb-dashboard-percent-circle-fill-gray: #717171;
$fb-dashboard-percent-circle-fill-blue: #23b7e5;
$fb-dashboard-percent-circle-text: #656565;

// WINNER STATISTIC
$fb-winner-statistic-gray: #dee0f8;
$fb-winner-statistic-gray-light: #dbcfcf;
$fb-winner-statistic-green: #009624;
$fb-winner-statistic-orange: #ff8800;
$fb-winner-statistic-red: #d50000;

// MAT SELECT
$fb-account-select-red: #DC3855;
$fb-account-select-gray: #e8e8e8;
$fb-account-select-dark-gray: #717171;
$fb-account-select-input-text-color: #fff;

// INFO BAR
$fb-info-bar-bg: #fff6eb;
$fb-info-bar-text: #8d8d8d;
$fb-info-bar-border: #e4cdbc;
$fb-alert-bar-bg: #ff2e2e;
$fb-alert-bar-text: #fff;
$fb-alert-bar-border: #fff;
$fb-success-bar-bg: #dff2e3;
$fb-success-bar-border: #83d88e;
$fb-success-bar-text: #000;
