/* ========================================================================
     Component: typo
 ========================================================================== */

@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);

body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #282828;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}
