@import '../../../shared/styles/colors';
@import '../../../shared/styles/app/media-queries';
@import '../../../shared/styles/app/variables';
@import '../../styles/app/form-elements';

$prefix-containter-height: 35px;

.switch {
  .locked {
    background-color: $fb-main-color;
    border-color: $fb-main-color;
  }
  input:checked + .locked:before {
    box-sizing: border-box;
    font-family: FontAwesome;
    content: '\F023';
    color: $fb-main-color;
    position: absolute;
    font-size: 12px;
    top: 0.5px;
    right: 5.5px;
    z-index: 50;
  }
}

.sm-expander {
  flex: 1 0;
}

.lg-expander {
  flex: 2 0;
}

.no-padding {
  padding: 0;
}

.floating-alert {
  position: absolute;
}

.full-column {
  height: 100%;
}

.fb-user-form,
.fb-details-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 290px;

  .form-fieldset-prefix {
    line-height: $prefix-containter-height;
    text-align: right;
    height: $prefix-containter-height;
    padding: 0 10px;
    background-color: $default-disable-color;
    opacity: 1;
  }

  //label {
  //  margin-top: 10px;
  //}
  .form-group {
    width: 100%;
    order: 0;

    &-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-group-label {
        flex: 0 0 100%;
      }

      .form-fieldset-select {
        flex: 0 0 32%;
        margin-right: 3%;
      }

      .phone-input {
        flex: 0 0 calc(100% - 70px - 15px);
      }

      .phone-prefix-input {
        flex: 0 0 70px;
      }
    }
  }
  .fb-address-container {
    display: flex;
    flex-direction: row;
  }
}

// @todo this should be restructured as it makes editing the code difficult as hell to change
.fb-details-form {
  .submit-panel {
    z-index: 220;
    order: 1;
  }
  .fb-radio-positioner,
  .fb-location-positioner,
  .full-column {
    order: 2;
    margin-left: 20px;
  }
}

.form-group.required {
  .control-label:after {
    content: '*';
    color: red;
  }
}

@media (max-width: 860px) {
  .fb-details-form {
    height: auto;
    max-height: none;
    .submit-panel {
      order: 2;
    }

    .fb-radio-positioner,
    .fb-location-positioner,
    .full-column {
      order: 1;
      margin-left: 0;
    }
  }
}

.submit-panel-spacer {
  display: block;
  height: 66px;
  width: 100%;
  background: transparent;
}

.submit-panel {
  z-index: 9998;
  background: $content-heading-bg;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 66px;
  border-top: 1px solid $panel-global-border;
  padding-right: 100px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  .btn-foodback {
    align-self: center;
    margin-left: 5px;
  }
}

.c-radio {
  input[type='radio'] + span:nth-of-type(1) {
    background-color: #fff !important;
  }

  input[type='radio']:checked + span:nth-of-type(1) {
    border-color: $fb-main-color !important;

    &:before {
      color: $fb-main-color !important;
      margin: 0;
      //left: -2px;
    }

    .fa {
      &:hover {
        border-color: $fb-main-color !important;
      }
    }
  }

  input[type='radio']:disabled {
    & + span:nth-of-type(1) {
      border-color: $ui-bg-color !important;
      cursor: not-allowed !important;

      &:before {
        color: $ui-bg-color !important;
      }

      & + div,
      & + div * {
        cursor: not-allowed !important;
      }
    }
  }

  &:hover {
    span:nth-of-type(1) {
      border-color: $fb-main-color !important;
    }
  }
}

.c-checkbox {
  input[type='checkbox'] + span:nth-of-type(1) {
    background-color: white !important;
  }

  input[type='checkbox']:checked + span:nth-of-type(1) {
    border-color: $fb-main-color !important;
    background-color: $fb-main-color !important;

    &:before {
      color: white !important;
    }

    .fa {
      &:hover {
        border-color: $fb-main-color !important;
      }
    }
  }

  input[type='checkbox']:disabled {
    & + span:nth-of-type(1) {
      border-color: $ui-bg-color !important;
      cursor: not-allowed !important;
      background-color: $fb-gray-no-active !important;

      &:before {
        color: $ui-bg-color !important;
      }

      & + div,
      & + div * {
        cursor: not-allowed !important;
      }
    }
  }

  &:hover {
    span:nth-of-type(1) {
      border-color: $fb-main-color !important;
    }
  }
}

.c-checkbox input[type='checkbox']:checked + span.fb-checkbox,
.c-checkbox input[type='radio']:checked + span.fb-checkbox,
.c-radio input[type='checkbox']:checked + span.fb-checkbox,
.c-radio input[type='radio']:checked + span.fb-checkbox {
  border-color: $fb-main-color;
  background: $fb-main-color;
}

.c-checkbox:hover span.fb-checkbox,
.c-radio:hover span.fb-checkbox {
  border-color: $fb-main-color;
}

.c-checkbox input[type='checkbox'] + span.fb-checkbox,
.c-checkbox input[type='radio'] + span.fb-checkbox,
.c-radio input[type='checkbox'] + span.fb-checkbox,
.c-radio input[type='radio'] + span.fb-checkbox {
  background-color: white;
}

.form-control {
  &-flex-1 {
    flex: 1;
  }
}
