/* ========================================================================
     Component: half-float
 ========================================================================== */
@use 'sass:math';

//
// Float an absolute positioned element
// and align it with a half top and bottom offset
// relative to its parent
//
// .half-float
//  > .half-float-[top|bottom]
//
// ----------------------------------------------

$half-aligned-size: 120px;
$half-aligned-gutter: 5px;

.half-float {
  position: relative;
  margin-bottom: ($half-aligned-size, 2) + $half-aligned-gutter;

  .half-float-bottom,
  .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -(math.div($half-aligned-size, 2));
    width: $half-aligned-size;
    height: $half-aligned-size;
    margin-left: -(math.div($half-aligned-size, 2));
    z-index: 2;
  }

  .half-float-top {
    bottom: auto;
    top: -(math.div($half-aligned-size, 2));
  }

  // The next sibling elements gets a negative margin
  // and padding to collapse the space between
  & + * {
    margin-top: -(math.div($half-aligned-size, 2)) + $half-aligned-gutter;
    padding-top: math.div($half-aligned-size, 2) + $half-aligned-gutter;
  }
}
