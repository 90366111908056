@import '../colors';

.table.table-sortable > thead > tr > th {
  cursor: pointer;
  position: relative;
  background-image: none;
  padding-left: 22px;
}

.table.table-sortable > thead > tr > th:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  font-family: FontAwesome;
  content: '\f0dc';
  color: #ddd;
  font-size: 16px;
  margin-right: 8px;
}

.table.table-sortable > thead > tr > th.dont-sort {
  cursor: default !important;

  &:after {
    content: '' !important;
  }
}

.table.table-sortable > thead > tr > th.sort-false:after {
  content: '\f0de';
  color: #767676;
}
.table.table-sortable > thead > tr > th.sort-true:after {
  content: '\f0dd';
  color: #767676;
}

.fb-clickable-cell {
  cursor: pointer;
  &:hover {
    color: $fb-main-color;
  }
}

.table-heavy-condensed {
  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 2px;
  }
}
